/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { withTrans } from '../../i18n/withTrans';

const Layout = ({ children, t, i18n, pageContext, location }) => {
  return (
    <main>
      {React.cloneElement(children, { pageContext, location })}
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout);
